
/**
 * @name: 社区服务费管理-社区服务费发放明细
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 社区服务费管理-社区服务费发放明细
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  sendDetailPageApi,
  sendDetailExportApi,
  sendDetailDetailPageApi,
  sendDetailDetailExportApi
} from '@/apis/community-service-fee/detail'
import {
  ISendDetail,
  ISendDetailParams,
  ISendDetailDetailParams,
  ISendDetailDetail
} from '@/apis/community-service-fee/detail/types'
import config from '@/config'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class communityServiceFeesDetail extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISendDetail[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISendDetailParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ISendDetail> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: '发放日期',
        prop: 'grantTime',
        hide: true,
        search: true,
        type: 'daterange',
        searchSlot: true
      },
      {
        label: '发放日期',
        prop: 'date',
        align: 'center'
      },
      {
        label: '发放数量',
        prop: 'num',
        align: 'center'
      },
      {
        label: '发放总额',
        prop: 'price',
        align: 'center'
      },
    ]
  }

  price = '0.00'

  detailQuery: ISendDetailDetailParams = {
    page: 1,
    limit: 10,
    date: ''
  }

  detailForm: any = {}

  detailTotal = 0

  detailData: ISendDetailDetail[] = []

  detailDialog = false

  detailCrudOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '申报单ID',
        prop: 'id',
        align: 'center',
        width: 180
      },
      {
        label: '会员手机号',
        prop: 'phone',
        align: 'center',
        slot: true
      },
      {
        label: '申报类型',
        prop: 'type',
        align: 'center',
        dicData: [
          {
            label: '个人申报',
            value: 1
          },
          {
            label: '企业申报',
            value: 2
          },
        ]
      },
      {
        label: '预计金额（元）',
        prop: 'declarationPrice',
        align: 'center'
      },
      {
        label: '账户名称',
        prop: 'bankAccount',
        align: 'center'
      },
      {
        label: '银行卡号',
        prop: 'bankId',
        align: 'center'
      },
      {
        label: '开户行名称',
        prop: 'openingBankName',
        align: 'center'
      },
      {
        label: '支行名称',
        prop: 'bankName',
        align: 'center'
      },
      {
        label: '银行绑定手机号',
        prop: 'bankBindPhone',
        align: 'center'
      },
      {
        label: '申报时间',
        prop: 'declarationTime',
        align: 'center',
        width: 150
      }
    ]
  }

  exportExcel () {

    const query: ISendDetailParams = deepCopy(this.queryParam)

    if (query.grantTime && query.grantTime.length) {
      query.grantStartTime = query.grantTime[0]
      query.grantEndTime = query.grantTime[1]
    } else {
      query.grantStartTime = ''
      query.grantEndTime = ''
    }
    delete query.grantTime

    sendDetailExportApi(query).then(e => {
      exportFile(e, "社区服务费发放明细.xlsx")
    })
  }

  getDetailData () {
    return new Promise(resolve => {
      sendDetailDetailPageApi(this.detailQuery).then(e => {
        if (e) {
          this.detailData = e.list;
          this.detailTotal = e.total
          resolve('')
        }
      })
    })
  }

  openDetail (row: ISendDetail) {
    this.$set(this, 'detailQuery', {
      page: 1,
      limit: 10,
      date: row.date
    })
    this.getDetailData()
    this.detailDialog = true
  }

  exportDetailExcel () {
    sendDetailDetailExportApi(this.detailQuery).then(e => {
      exportFile(e, '发放明细.xlsx')
    })
  }

  getList () {
    this.tableLoading = true

    const query: ISendDetailParams = deepCopy(this.queryParam)

    if (query.grantTime && query.grantTime.length) {
      query.grantStartTime = query.grantTime[0]
      query.grantEndTime = query.grantTime[1]
    } else {
      query.grantStartTime = ''
      query.grantEndTime = ''
    }
    delete query.grantTime

    sendDetailPageApi(query).then(e => {
      if (e) {
        this.price = e.price
        this.tableData = e.page.list
        this.tableTotal = e.page.total
        this.tableLoading = false
      }
    })
  }

  created () {
    this.getList()
  }
}
