/**
 * @name: 社区服务费管理-社区服务费发放明细接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 社区服务费管理-社区服务费发放明细接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import { ISendDetailRes, ISendDetailParams, ISendDetailDetailParams, ISendDetailDetail } from "./types";
import { IPageRes } from "@/apis/page";

export const sendDetailPageApi = (params: ISendDetailParams) => get<ISendDetailRes>('/admin/user/community/service/fee/detail/query', params)

export const sendDetailExportApi = (params: ISendDetailParams) => get('/admin/user/community/service/fee/detail/export', params, 'blob')

export const sendDetailDetailPageApi = (params: ISendDetailDetailParams) => get<IPageRes<ISendDetailDetail[]>>('/admin/user/community/service/fee/check/detail/query', params)

export const sendDetailDetailExportApi = (params: ISendDetailDetailParams) => get('/admin/user/community/service/fee/check/detail/export', params, 'blob')
